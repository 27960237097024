import React from "react"
import styles from "./terms-and-condition.module.scss"

const ProcessIntro = () => {
  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        <h5>1. Definitions</h5>
        <p>
          The following Terms and Conditions document is a legal agreement
          between Octane Digital Ltd, hereafter “the Developer”, and “the
          Client” for the purposes of website design or development. These Terms
          and Conditions set forth the provisions under which the Client may use
          the services supplied.
        </p>
        <p>
          The Developer is an Internet web design provider offering the Client
          graphical design, HTML, CSS, Javascript, PHP, Drupal and other related
          computer programming languages.
        </p>
        <h5>2. Acceptance of Work</h5>
        <p>Estimates are valid for 30 days from date of issue.</p>
        <p>
          When the Client places an order to purchase a website or website
          updates from the Developer, the order represents an offer to the
          Developer to purchase the website or website updates. No contract for
          the supply of services exists between Client and Developer until the
          Developer sends an estimate to the Client. The estimate equals
          acceptance by the Developer (or third party supplier) of the Client’s
          offer to purchase services from the Developer and this estimate of
          work is a valid contract between Client and Developer along with an
          optional, written agreement between both parties.
        </p>
        <p>
          Any other services on the order that have not been included in the
          estimate or agreeement do not form part of the contract. The Client
          agrees to check that the details of the estimate, agreement and
          specifications are correct and should print and keep a copy for their
          records.
        </p>
        <p>
          The Developer is liable to withdraw the from contract at any time
          prior to acceptance.
        </p>
        <p>
          Additional work requested by the Client that is not specified in the
          agreed estimate is subject to an additional estimate by the Developer
          on receipt of specification. If the work is needed as part of an
          existing project, then this may affect time scale and overall delivery
          time of the project.
        </p>
        <p>
          If a functional specification and a set of testing criteria is
          included within the estimate, the Developer is responsible for
          fulfilling the testing criteria as the sole criteria for completion of
          the contract.
        </p>
        <p>
          The Client agrees to provide any needed information and content
          required by the Developer in good time to enable the Developer to
          complete a design or website work as part of an agreed project.
        </p>
        <p>
          Any work is subject to a minimum charge of the agreed hourly charge.
        </p>
        <h5>3. Permission and Copyright</h5>
        <p>
          Copyright of the completed web designs, images, pages, code and source
          files created by the Developer for the project shall be with the
          Client upon final payment.
        </p>
        <p>
          These terms of use grant a non-exclusive limited license so that the
          Client can use the design on one website on one domain name only. The
          Client is not permitted to use a design for more than one website
          without prior written agreement between the Client and the Developer.
        </p>
        <p>
          The Client agrees that resale or distribution of the completed files
          is forbidden unless prior written agreement is made between the Client
          and the Developer.
        </p>
        <p>
          The Client hereby agrees that all media and content made available to
          the Developer for use in the project are either owned by the Client or
          used with full permission of the original authors. The Client agrees
          to hold harmless, protect and defend the Developer from any claim or
          suit that may arise as a result of using the supplied media and
          content.
        </p>
        <p>
          The Client agrees that the Developer may include development credits
          and links within any code the Developer designs, builds or amends. If
          the Developer designs a website for a Client, then the Client agrees
          that the Developer may include a development credit and link displayed
          on the Client’s website. If the Developer builds or amends a website
          for a Client, then the Client agrees that the Developer may include a
          development credit and link displayed on the Client’s web page, which
          may be within the code but not displayed on a web browser if requested
          by the Client.
        </p>
        <p>
          The Client agrees that the Developer reserves the right to include any
          work done for the Client in a portfolio of work.
        </p>
        <p>
          The Client agrees to abide by the terms of any third party software or
          media included within any work done for the Client. Examples of this
          include, but are not limited to, Googlemaps, Media under the Creative
          Commons license, RSS feeds, Open Source GPL Software etc.
        </p>
        <h5>4. Material</h5>
        <p>The Developer reserves the right to refuse to handle:</p>
        <ul>
          <ol>1. Any media that is unlawful or inappropriate.</ol>
          <ol>2. Any media that contains a virus or hostile program.</ol>
          <ol>
            3. Any media that constitutes harassment, racism, violence,
            obscenity, harmful intent or spamming.
          </ol>
          <ol>
            4. Any media that constitutes a criminal offence, or infringes
            privacy or copyright.
          </ol>
        </ul>
        <h5>5. Domain names and Hosting</h5>
        <p>
          The Developer can, at its own discretion, but is not obliged to, offer
          domain name registration and hosting via a third party service.
        </p>
        <p>
          The Client agrees that registration of a domain name does not provide
          endorsement of the right to use the name. The Client is responsible
          for ensuring they have due title to the domain name. The Developer
          holds no liability and the Client hereby agrees to indemnify and hold
          harmless the Developer from any claim resulting from the Client’s
          registration of a domain name.
        </p>
        <p>
          The domain name is registered in the Client’s own name, with the
          address and contact details of the Developer. The Client should be
          aware that a domain name is registered with a third party and as such
          the Client shall agree to fully abide by the terms and conditions set
          out by the third party for such services.
        </p>
        <p>
          The Client agrees to take all legal responsibility for use of third
          party domain name and hosting services and supply truthful details to
          the third party services.
        </p>
        <p>
          The Client agrees that information submitted for registration of
          domain names is then available to the general public via the Nominet
          Whois system. However, Clients who are using their website for
          non-trading purposes may ask the third party registrar for their
          contact information not to be included in the Nominet Whois system.
        </p>
        <p>
          The Client is liable to pay the Developer for any domain name
          registrations and the initial set-up of the hosting if included as
          part of the website build.
        </p>
        <p>
          Any support relating to the domain name, hosting and email services is
          between the Client and the third party service.
        </p>
        <p>
          Any other domain name and hosting services or costs not included by
          the Developer, including but not limited to further domain name
          registration fees, domain name transfer charges, yearly domain name
          renewals, hosting charges, yearly hosting renewals, hosting upgrades,
          extra disk space, bandwidth and any other related or hidden charges,
          are to be paid by the Client to the third party services.
        </p>
        <p>
          The Client agrees to pay the domain name and hosting fees as soon as
          required by the third party. Any modifications needed to the domain
          name or hosting services are to be made between the Client and third
          party service.
        </p>
        <p>
          The Client agrees that if at any time their contact details, including
          email address, change, it is their responsibility to contact the third
          party and update their contact details. Failure to do so may mean that
          renewal invoices for the domain name and hosting services are not
          received by the Client.
        </p>
        <p>
          Payment for domain name and hosting services is to be made immediately
          on receipt of an invoice from the third party service. Failure to
          comply with the payment terms may result in the Client’s domain name
          becoming available to another party and/or the website and email
          services becoming unavailable.
        </p>
        <p>
          The Client agrees to pass on FTP details and any other access details
          relating to their domain name and hosting account that the Developer
          requires to upload the website if required as part of a project.
        </p>
        <p>
          The Developer reserves the right without notice to cancel, reject or
          refuse work with domain names or hosting services without reason for
          such rejection or refusal. The Client agrees to be liable for their
          use of the domain name, hosting and email services with the third
          party and hereby agrees to indemnify and hold harmless the Developer
          from any claim resulting from the Client’s publication of material and
          use of the domain name, hosting and email services.
        </p>
        <p>
          The Client agrees to take full responsibility for all usage of the
          domain name, hosting and email services and to fully abide by the
          terms and conditions set out by the third party for such services.
        </p>
        <h5>6. Projects</h5>
        <p>
          The Client agrees that an HTML page built from a graphic design may
          not exactly match the original design because of the difference
          between the display in design software and the rendering of HTML code
          by Internet browser software. The Developer agrees to try to match the
          design as closely as is possible when building the code.
        </p>
        <p>
          During a website project it is important that the Client communicates
          information to the Developer to achieve the required result.
        </p>
        <p>
          The Client agrees they are permitted a maximum of 4 hours of
          alteration on projects of $3000, with an additional 1 hour of
          alteration available per whole $1500 of cost after that, unless
          estimated otherwise. All alterations are to be requested in writing
          either by email or postal mail by the Client. After the allocated time
          for alterations is used up, either in design or coding, the Developer
          reserves the right to advise the Client of such and send a separate
          quotation to the Client and to request payment for any further
          alterations. The Developer reserves the right to request payment be
          received for further alterations before continuing work. Upon
          completion of agreed design, the Client is asked to confirm in writing
          by email or postal mail that the design and the screen size is signed
          off as complete and agree that any further design alterations are
          chargeable.
        </p>
        <p>
          If the Client requests design or content alterations to pages that
          have already been completed, new pages or different functionality
          other than that specified in the original estimate, the Developer
          reserves the right to estimate separately for these alterations.
        </p>
        <p>
          If optimised pages are included as part of the project, the Developer
          will optimise the Client’s web pages that already make up part of the
          project. Optimised pages is not creation of new pages. The
          optimisation of the web pages can include the meta tags, keywords,
          description, title, alt tags and text provided by the Client.
        </p>
        <p>
          The Developer endeavours to create pages that are accessible to search
          engines. However, the Developer gives no guarantee that the site will
          become listed with search engines.
        </p>
        <p>
          If an error or issue with the design or code arises during the
          project, which does not allow the design or code to match the original
          specification, then the Client agrees that the Developer can apply a
          nearest available alternative solution.
        </p>
        <p>
          The Developer at all times applies reasonable skill and care in
          provision of services. On request, the Developer can create a copy of
          the website on one CD to be posted to the Client on project
          completion. A small charge will be made to cover the cost of this,
          unless estimated otherwise.
        </p>
        <p>
          Once the project is completed, the Developer will upload the website
          to the Client’s live web address if included as part of a project.
        </p>
        <p>
          After site completion, a Client or a third party of their choosing may
          wish to edit their website code themselves to make updates. However,
          the Client agrees that in so doing they assume full responsibility for
          any issues which occur as a result of changing the code themselves. If
          the Client or a third party of their choosing edits the website code
          and this results in functionality errors or the page displaying
          incorrectly, then the Developer reserves the right to quote for work
          to repair the website.
        </p>
        <p>
          The Developer reserves the right to assign subcontractors in whole or
          as part of a project if needed.
        </p>
        <p>
          The Developer will keep a copy of the site and design source files
          when a website project is being worked on. However, the Client agrees
          that it is their responsibility to have regular backups made by
          themselves or the third party hosting services in case of a software
          or hardware failure at the third party hosting servers.
        </p>
        <p>
          All communications between Developer and Client shall be by telephone,
          email, Skype or postal mail, except where agreed at the Developer’s
          discretion.
        </p>
        <h5>7. Accessibility & Web Standards</h5>
        <p>
          The Developer tests sites and templates to ensure they comply with WAI
          accessibility standards to Level A conformance at time of sale. Should
          the Client request that the Developer alter the site or templates to
          meet specific WAI accessibility guidelines, or if updated WAI
          accessibility guidelines were introduced after the site or templates
          were sold to the Client, the Developer reserves the right to quote
          separately for any additional work needed. If the Client uses Drupal
          Themes or Modules, or other CMS systems that are not built by the
          Developer, the overall page may not meet WAI accessibility standards
          to Level A conformance.
        </p>
        <p>
          The Developer tests sites and templates to ensure they comply with W3C
          CSS standards as they are at time of sale. Should updated W3C CSS
          guidelines be introduced after the site or templates were sold to the
          Client, the Developer reserves the right to quote separately for any
          additional work needed. If the Client uses Drupal Themes or Modules,
          or other CMS systems that are not built by the Developer, the overall
          page may not meet W3C CSS standards.
        </p>
        <p>
          The Developer shall make every effort to ensure sites are designed to
          be viewed by the majority of visitors. Sites are designed to work with
          the standard development platform, which includes recent versions of
          the main browsers, Internet Explorer and Mozilla Firefox. The Client
          agrees that the Developer cannot guarantee correct functionality with
          all browser software across different operating systems.
        </p>
        <p>
          The Client agrees that, following handover of files, any updated
          software versions of the browsers detailed in the standard development
          platform, including the browsers Internet Explorer and Mozilla
          Firefox, domain name set-up changes or hosting set-up changes
          thereafter may affect the functionality and display of their website.
          As such, the Developer reserves the right to quote for any work
          involved in changing the website design or website code for it to work
          with updated browser software, domain name or hosting changes.
        </p>
        <p>
          The Client agrees that more advanced applications on a website page
          may require a newer browser version or plug-in.
        </p>
        <h5>8. Payment Terms</h5>
        <p>Prices are subject to change without notice.</p>
        <p>All estimated prices are exempt of VAT unless specified.</p>
        <p>
          All invoices must be paid in full within 30 days of the invoice date,
          except where agreed at the Developer’s own discretion.
        </p>
        <p>
          The Developer reserves the right to decline further work on a project
          if there are invoices outstanding with the Client.
        </p>
        <p>
          The Developer reserves the right to remove its work for the Client
          from the Internet if payments are not received.
        </p>
        <h5>9. Liability and Warranty Disclaimer</h5>
        <p>
          The Developer provides their website and the contents thereof on an
          ‘as is’ basis and makes no warranties with regard to the site and its
          contents, or fitness of services offered for a particular purpose. The
          Developer cannot guarantee the functionality or operations of their
          website or that it will be uninterrupted or error free, nor does it
          warrant that the contents are current, accurate or complete.
        </p>
        <p>
          The Client agrees that the Developer is not liable for any bugs,
          performance issues or failure of their Drupal software as Drupal is
          open-source software distributed under the GPL (“GNU General Public
          License”) and is maintained and developed by a community of thousands
          of users and developers. Any bugs, performance issues or failure with
          the software will be directed to the Drupal Development community via
          drupal.org.
        </p>
        <p>
          The Developer endeavours to provide a website within given delivery
          time scales to the best of its ability. However, the Client agrees
          that the Developer is not liable for any claims, losses, costs
          incurred or compensation due to any failure to carry out services
          within a given delivery time scale.
        </p>
        <p>
          The Client agrees that the Developer is not liable for any failure to
          carry out services for reasons beyond its control, including but not
          limited to acts of God, telecommunication problems, software failure,
          hardware failure, third party interference, Government, emergency on a
          major scale or any social disturbance of extreme nature such as
          industrial strike, riot, terrorism and war or any act or omission of
          any third party services.
        </p>
        <p>
          The Developer is not liable for any consequences or financial losses
          such as, but not limited to, loss of business, profit, revenue,
          contract, data or potential savings, relating to services provided.
        </p>
        <p>
          On handover of files from Developer to Client, the Client shall assume
          entire responsibility in ensuring that all files are functioning
          correctly before use.
        </p>
        <p>
          Whilst every effort is made to make sure files are error free, the
          Developer cannot guarantee that the display or functionality of the
          web design or the website will be uninterrupted or error free. If,
          after handover of files, errors are found in code the Developer has
          created and the standard development platform, domain name set-up and
          hosting set-up are the same as when work began, then the Developer can
          correct these errors for the Client free of charge for a period of 3
          months, after acceptance of the work. After the 3-month period, the
          Developer reserves the right to quote separately for any work involved
          in correcting an error.
        </p>
        <p>
          If, after handover of files, errors are found in code the Developer
          has created and the standard development platform, or the domain name
          set-up or hosting set-up have been changed, the Developer can correct
          errors and reserves the right to quote separately for any additional
          work needed as a result of changes to the browser software, domain
          name set-up or hosting set-up.
        </p>
        <p>
          Should the Client go into compulsory or involuntary liquidation or
          cannot pay its debts in the normal course of business, the Developer
          reserves the right to cancel forthwith any projects and invoice the
          Client for any work completed.
        </p>
        <p>
          The Developer shall have no liability to the Client or any third
          parties for any damages, including but not limited to claims, losses,
          lost profits, lost savings, or other incidental, consequential, or
          special damages arising out of the operation of or inability to
          operate these web pages or website, even if the Developer has been
          advised of the possibility of such damages.
        </p>
        <p>
          There are sometimes laws and taxes that affect Internet e-commerce.
          The Client agrees that it is their responsibility to comply with such
          laws and will hold harmless, protect, and defend the Developer and its
          subcontractors from any claim, suit, penalty, tax, or tariff arising
          from the Client’s exercise of Internet e-commerce.
        </p>
        <p>
          The Developer may from time to time recommend to the Client that
          updates are needed to their site, including but not limited to new
          legislation compliance, software compatibility and web standards. The
          Developer reserves the right to quote for any updates as separate
          work. The Client agrees that the Developer is not liable for any
          failure to inform or implement these updates to their site. The Client
          agrees that it shall defend, indemnify, save and hold the Developer
          harmless from any and all demands, liabilities, costs, losses and
          claims arising from omission to inform or implement these updates.
        </p>
        <h5>10. Termination</h5>
        <p>
          Termination of services by the Client must be requested in a written
          notice and will be effective on receipt of such notice. E-mail or
          telephone requests for termination of services will not be honored
          until and unless confirmed in writing. The Client will be invoiced for
          design work completed to the date of first notice of cancellation for
          payment in full within thirty (30) days.
        </p>
        <h5>11. Indemnification</h5>
        <p>
          The Client agrees to use all Developer services and facilities at
          their own risk and agrees to defend, indemnify, save and hold the
          Developer harmless from any and all demands, liabilities, costs,
          losses and claims, including but not limited to legal fees against the
          Developer or its associates that may arise directly or indirectly from
          any service provided or agreed to be provided or any product or
          service sold by the Client or its third parties.
        </p>
        <p>
          The Client agrees that this indemnification extends to all aspects of
          the project, including but not limited to website content and choice
          of domain name.
        </p>
        <p>
          The Client also agrees to indemnify, hold harmless and defend, the
          Developer against any liabilities arising out of injury to property or
          person caused by any any product or service sold by the Client or any
          service provided or agreed to be provided or by third parties,
          including but not limited to infringement of proprietary rights,
          misinformation, infringement of copyright, delivery of defective
          services or products that are harmful to any company, person,
          business, or organisation.
        </p>
        <h5>12. Nondisclosure</h5>
        <p>
          The Developer and any third party associates agree that, unless
          directed by the Client, it will not at any time during or after the
          term of this agreement disclose any confidential information. The
          Client agrees that it will not convey any confidential information
          about the Developer to another party, unless directed by the
          Developer.
        </p>
        <h5>13. Privacy Policy</h5>
        <p>
          The Developer and any third party associates shall use information
          provided by the Client in relation to this agreement in accordance
          with the Data Protection Act 1998. This infomation will also be used
          to identify the Client in communications with them and to contact the
          Client from time to time to offer them services or products that may
          be of interest to or benefit the Client.
        </p>
        <h5>14. Interpretation</h5>
        <p>
          The Developer reserves the right to terminate a project with a Client
          at any time without prior notification if it finds the Client in
          breach of these Terms and Conditions. The Developer shall be the sole
          arbiter in deciding what constitutes a breach. No refunds will be
          given in such a situation.
        </p>
        <p>
          Where one or more terms of this contract are held to be void or
          unenforceable for whatever reason, any other terms of the contract not
          so held will remain valid and enforceable at law.
        </p>
        <p>
          Any and all matters pursuant to this agreement are governed by English
          Law and are under exclusive jurisdiction of the English Courts.
        </p>
        <p>
          This agreement shall be governed by the laws of England and Wales
          which shall claim venue and jurisdiction for any legal motion or claim
          arising from this agreement. This agreement is void where prohibited
          by law.
        </p>
        <p>
          By accepting a estimate or making a payment of invoice to use the
          services supplied, the Client acknowledges to have read, understand,
          and accept the Terms and Conditions of this Agreement, and agrees to
          be legally binding by these Terms and Conditions.
        </p>
        <p>
          The Developer reserves the right to alter these Terms and Conditions
          at any time without prior notice. The latest Terms and Conditions can
          be found at the Developer’s website at http://davidcreativestudio.com
        </p>
        <h5>15. Statutory Rights</h5>
        <p>
          These Terms and Conditions do not affect your statutory rights as a
          consumer.
        </p>
      </div>
    </div>
  )
}

export default ProcessIntro
